import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { closeTab, getSettings, getTabs } from '../../api/bot.api';
import { ChainIds } from '../../libs/constants';
import { shortenAddress } from '../../libs/utils';
import { RootState } from '../../store';
import { deleteTabAction, setPricesAction, setSettingsAction, setTabsAction } from '../../store/slice';
import AddTabModal from './AddTabModal';
import OrcaPanelContainer from './OrcaPanelContainer';
import SettingsPopover from './SettingsPopover';


export default function MainContainer() {
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = useSelector((state: RootState) => state.tabs)
    const settings = useSelector((state: RootState) => state.settings)

    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        const index = tabs.findIndex(tab =>tab._id === newValue);
        if(index !== -1){
            setTabIndex(index);
        }
    };

    const handleCloseTab = async (tabId: string) => {
        const res = await closeTab({tabId});
        if(!res.success){
            toast.error(res.msg);
            return;
        }
        dispatch(deleteTabAction({tabId: tabId}));
    }

    useEffect(() => {
        (async () => {
            const res1 = await getSettings();
            if(res1.success){
                dispatch(setSettingsAction(res1.data))
            }
            const res2 = await getTabs();
            if(res2.success){
                dispatch(setTabsAction({tabs: res2.data.tabs}));
                if(res2.data.prices){
                    dispatch(setPricesAction(res2.data.prices))
                }
            }
        })()

        const timer = setInterval(async () => {
            const res = await getTabs();
            if(res.success){
                dispatch(setTabsAction({tabs: res.data.tabs}))
                if(res.data.prices){
                    dispatch(setPricesAction(res.data.prices))
                }
            }
        }, 5000)

        return () => {
            clearInterval(timer);
        }
    }, [dispatch])


    useEffect(() => {
        if(tabIndex >= tabs.length){
            setTabIndex(tabs.length === 0 ? 0 : tabs.length - 1)
        }
    }, [tabs])
    
    return (
        <Box pt={2} sx={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Box px={2} sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box>
                    <SettingsPopover settings={settings}/>
                </Box>
                <Box sx={{display: 'flex', border: '1px solid', borderColor: 'divider', py: 1, px: 2}}>
                    AutoCollect:<Typography ml={1} mr={3} color={settings.autoCollect ? 'primary' : 'divider'}>{settings.autoCollect ? 'Enabled' : 'Disabled'}</Typography>
                    Auto Rebalance:<Typography ml={1} color={settings.autoRebalance ? 'primary' : 'divider'}> {settings.autoRebalance ? 'Enabled' : 'Disabled'}</Typography>
                </Box>
                <AddTabModal/>
            </Box>
            <Box sx={{width: '100%', display: 'flex', paddingLeft: '10%'}}>
                <Tabs value={tabs[tabIndex]?._id} onChange={handleTabChange} centered>
                    {tabs.map((tab) => (
                        <Tab key={tab._id} label={shortenAddress(tab.pubKey)} value={tab._id}/>
                    ))}
                </Tabs>
            </Box>
            
            <Box sx={{flex: 1, width: '100%', display: 'flex', flexDirection: 'column'}}>
                {tabs.map((tab, index) => {
                    if(tab.chainId === ChainIds.POLYGON || tab.chainId === ChainIds.ARBITRUM){
                        return(<></>
                            // <PanelContainer key={tab._id} tabId={tab._id} chainId={tab.chainId} isCurrent={tabValue===tab._id} closeTab={handleCloseTab}/>
                        )
                    }else{
                        return(
                            <OrcaPanelContainer key={tab._id} tabData={tab} isCurrent={tabIndex === index} closeTab={handleCloseTab}/>
                        )
                    }
                })}
            </Box>
        </Box>
    );
}