
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { setTabSettings } from '../../api/bot.api';
import { displayWithNonZeroDecimals, shortenAddress } from '../../libs/utils';
import { setTabSettingsAction } from '../../store/slice';
import OrcaPositionTable from './OrcaPositionTable';
import TopMenu from './TopMenu';
import { TabInterface } from './types';

interface OrcaPanelContainerProps {
    tabData: TabInterface
    isCurrent: boolean;
    closeTab: (tabId: string) => void;
}

const OrcaPanelContainer = ({tabData, isCurrent, closeTab}: OrcaPanelContainerProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch()
    const handleSettingsChange = async (key: string, value: boolean) => {
        const newTabSettings = {...tabData.settings, [key]: value};
        const res = await setTabSettings({tabId: tabData._id, settings: newTabSettings});
        if(res.success){
            toast.success("Tab settings changed");
            dispatch(setTabSettingsAction({tabId: tabData._id, settings: {...res.data}}));
        }else{
            toast.error(res.msg);
        }
    }

    const handleCopyAddress = () => {
        navigator.clipboard.writeText(tabData.pubKey)
            .then(() => {
                setTooltipOpen(true);
                setTimeout(()=>{
                    setTooltipOpen(false);
                }, 1500)
            })
            .catch((err) => {
                console.error("Failed to copy address: ", err);
            });
    }


    return (
        <Box sx={{ flex: 1, display: isCurrent ? 'flex' : 'none', flexDirection: 'column'}}>
            <Box px={2} sx={{flex: 1, width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: '#0b0b0c'}}>
                <Box px={1} py={1} sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', 
                                borderBottom: `1px solid ${theme.palette.divider}`, }}>
                    <Box display='flex' gap={2}>
                        <TopMenu closeTab={()=>closeTab(tabData._id)}/>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography  variant='body2' ml={4} mr={1}>{shortenAddress(tabData.pubKey, 6)}</Typography>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={tooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                        >
                            <IconButton size="small" onClick={handleCopyAddress}>
                                <ContentCopyIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box flex={1} display='flex' justifyContent={'center'}>
                        <Typography variant='h6'>Concentrated LPs</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                        <FormControlLabel 
                            control={
                                <Switch
                                    checked={tabData.settings.autoCollect}
                                    onChange={(e)=>handleSettingsChange("autoCollect", e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Auto Collect" />
                        <FormControlLabel 
                            control={
                                <Switch
                                    checked={tabData.settings.autoRebalance}
                                    onChange={(e)=>handleSettingsChange("autoRebalance", e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                        } label={"Auto Rebalance"} />
                    </Box>
                </Box>
                <Box sx={{flex: 1, width: '100%', position: 'relative', overflowY: 'auto'}}>
                    <OrcaPositionTable tabData={tabData}/>
                </Box>
            </Box>
            <Box p={1} sx={{borderTopColor: 'divider', borderTopStyle: 'solid', borderTopWidth: '1px', display: 'flex', alignItems: 'center'}}>
                <Box sx={{flex: 1, display: 'flex', alignItems: 'center', px: 2,
                     '& .MuiTypography-root': { mr: 2 }
                }}>
                    {tabData.balance && Object.entries(tabData.balance).map(([symbol, amount]) => (
                        <>
                            {Number(amount) > 0 && <Typography key={symbol} variant='subtitle2'>{symbol}: {displayWithNonZeroDecimals(amount, 1)}</Typography>}
                        </>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default OrcaPanelContainer
